<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.show_conclusions") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row" v-loading="loadingData">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-row>
            <el-col class="ml-5" :span="8">
              {{ $t("message.doctor_name") }} :
              <b>{{
                form.doctor
                  ? form.doctor.name +
                    " " +
                    (form.doctor.surname ? form.doctor.surname : "") +
                    " " +
                    (form.doctor.patronymic ? form.doctor.patronymic : "")
                  : ""
              }}</b>
            </el-col>
            <el-col :span="8">
              {{ $t("message.date") }} :
              <b>{{ form.date_time }}</b>
            </el-col>
            <el-divider></el-divider>
            <el-col :span="24">
              <el-card class="ml-5" style="width: 826px" shadow="always">
                <el-form ref="form" status-icon :model="form" :rules="rules">
                  <el-form-item>
                    <Tinymce
                      :id="tinymce_id"
                      ref="editorIn"
                      class="naw_Editor"
                      v-model="form.content"
                      :height="800"
                    />
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import list from "@/utils/mixins/list";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [form, list],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    Tinymce,
  },
  data() {
    return {
      //   editor: DecoupledEditor,
      tinymce_id: "create_conslusion_tiny_child",
      editorConfig: {},
      loadingData: false,
    };
  },
  // watch: {
  //   reloadModel: {
  //     handler: function () {
  //       if (this.reloadModel) {
  //         this.loadModel();
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      rules: "laboratoryConclusions/rules",
      model: "laboratoryConclusions/model",
      columns: "laboratoryConclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      update: "laboratoryConclusions/update",
    }),
    editLaboratoryConclusion() {},
    opened() {
      this.form = JSON.parse(JSON.stringify(this.model));
      // this.$refs["editorIn"].setContent(this.form.content);
    },
    closed() {
     
    },
    submit(close = true) {
      this.loadingButton = true;
      this.update({ id: this.form.id, data: this.form })
        .then((res) => {
          this.loadingButton = false;

          this.$emit("c-close", { drawer: "laborantDrawerUpdate" });
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = null;
      this.$emit("c-close", { drawer: "laborantDrawerUpdate" });
    },
  },
};
</script>
