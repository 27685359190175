<template>
  <div class="tablePrint">
    <div class="modal_header">
      <!-- <el-row :gutter="10">
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.write_conclusions") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple text-center">
            <div class>
              {{ $t("message.patient_0") }}:
              <b style="text-transform: uppercase">{{
                order_model.patient
                  ? order_model.patient.surname +
                    " " +
                    order_model.patient.first_name
                  : " - "
              }}</b>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                v-if="activeName == 'second'"
                :loading="waitingPrint"
                type="success"
                @click="print('conclusion/print?id=' + old_form.id)"
                >{{ $t("message.printing") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="modal-body editor-content">
          <el-steps :active="active" finish-status="success" simple>
            <!-- <el-step
              :disabled="true"
              @click.native="tabTrigger(1, 'first')"
              :title="$t('message.write_conclusions')"
            ></el-step>
            <el-step
              @click.native="tabTrigger(2, 'second')"
              :title="$t('message.printing')"
            ></el-step> -->
          </el-steps>
          <el-tabs v-model="activeName">
            <el-tab-pane name="first">
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="d-flex print_title_list">
                    <div class="ttitle_Text">
                      {{ selectedTemplate ? selectedTemplate.name : "" }}
                    </div>

                    <!-- <el-col :span="8">
                      <el-select
                        clearable
                        v-model="form.sick_number_id"
                        style="width: 100%"
                        :placeholder="$t('message.sickNumber')"
                      >
                        <el-option
                          v-for="(item, index) in sick_numbers"
                          :key="index"
                          :label="item.number"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-col> -->
                    <div class="d-flex" style="flex: 1; justify-content: flex-end">
                      <el-col :span="8">
                        <users v-model="form.doctor_ids" :multiple="true" :size="'medium'" :own_id="user_id"
                          :doctor="true"></users>
                      </el-col>
                      <el-col :span="8">
                        <el-input clearable v-model="form.diagnos" :placeholder="$t('message.clinic_diagnos')"></el-input>
                      </el-col>

                      <el-select clearable filterable v-model="form.sick_number_id"
                        style="width: 300px; margin-right: 20px" :placeholder="$t('message.sickNumber')">
                        <el-option v-for="(item, index) in sick_numbers" :key="index" :label="item.number"
                          :value="item.id"></el-option>
                      </el-select>
                      <!-- <el-date-picker
                        v-model="form.date_time"
                        :format="'dd.MM.yyyy'"
                        :value-format="'dd.MM.yyyy'"
                        type="datetime"
                        style="width: 300px !important; display: inline-block"
                        :placeholder="$t('message.placeholder_time_date')"
                        class="mr-3"
                      ></el-date-picker> -->
                      <!-- <el-upload
                              class="upload-demo alima mr-3"
                              action="https://jsonplaceholder.typicode.com/posts/"
                              multiple
                              :limit="3"
                              :file-list="fileList"
                            >
                              <el-button
                                size="small"
                                icon="el-icon-upload"
                                style="height:38px"
                                type="success"
                              >Выберите файл</el-button>
                            </el-upload> -->

                      <el-button style="height: 38px" v-if="order_model.conculation &&
                        order_model.conculation.content
                        " @click="dialogTableVisible = true">{{ $t("message.back") }}</el-button>
                      <!-- print show vaqtincha stop -->
                      <el-button :loading="waitingPrint" type="success" style="height: 38px" icon="el-icon-printer"
                        @click="printExample(order_model, form)">{{ $t("message.show_0") }}</el-button>
                      <!-- <el-button
                        :loading="waitingPrint"
                        type="success"
                        style="height: 38px"
                        icon="el-icon-printer"
                        @click="watchConclusion(order_model, form)"
                        >{{ $t("message.show_0") }}</el-button
                      > -->

                      <el-button size="small" icon="el-icon-delete" style="height: 38px" type="danger" @click="clear()">{{
                        $t("message.clear") }}</el-button>

                      <el-button style="height: 38px" type="primary" :disabled="form.content == '' || loadingButton ? true : false
                        " @click="submit">
                        {{ $t("message.save_and_go") }}
                        <i class="el-icon-arrow-right el-icon-right"></i>
                      </el-button>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8" v-loading="loadingTemplates">
                  <div class="boxshii xh_scrol" style="height: calc(60vh - 200px)">
                    <el-form ref="form" status-icon :model="form" :rules="rules">
                      <el-form-item prop="service_id">
                        <el-select clearable @change="chanseService(form.service_id)" v-model="form.service_id"
                          style="width: 100%" :placeholder="$t('message.services')">
                          <el-option v-for="(item, index) in order_model.services" :key="index" :label="item.name"
                            :value="item.id" style="
                              width: 100% !important;
                              display: inline-block;
                            "></el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>

                    <el-input clearable :placeholder="$t('message.search_template')" v-model="filterText"></el-input>
                    <div class="favorites mt-1">
                      <el-tag style="cursor: pointer" @click="selected(item)" type="success" closable
                        @close="addFavorites(item.id)" class="ml-1 mt-1" v-for="(item, index) in favorites"
                        :key="index">{{ item.name }}</el-tag>
                    </div>

                    <el-tree class="mt-2 filter-tree" :data="data" node-key="id" @node-click="selected"
                      :filter-node-method="filterNode" ref="tree">
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="maxWidht ss">
                          {{ data.name }}
                          <span v-if="data.code" style="color: blue">
                            ( {{ data.code }} )
                          </span>
                        </span>
                        <span class="tree-meta-price" v-if="data.children.length == 0">
                          <!-- <el-button
                            v-if="!data.favorite"
                            circle
                            @click="addFavorites(data.id)"
                            style="border: none; background: none"
                            icon="el-icon-star-off"
                          ></el-button>
                          <el-button
                            v-if="data.favorite"
                            circle
                            @click="addFavorites(data.id)"
                            style="border: none; background: none"
                            icon="el-icon-check"
                          ></el-button> -->
                        </span>
                      </span>
                    </el-tree>
                  </div>
                  <div class="boxshii xh_scrol" style="height: calc(40vh)">
                    <h3>
                      <el-button v-if="order_model.conculation" @click="editLaboratoryConclusion" style="float: right">{{
                        $t("message.update") }}</el-button>
                      <el-divider content-position="left">
                        Заключение лаборатории
                      </el-divider>
                    </h3>
                    <div v-if="order_model.conculation" v-html="order_model.conculation &&
                      order_model.conculation.content
                      ">
                      <br />
                    </div>
                  </div>
                </el-col>
                <el-col :span="13">
                  <div class="Editor_widht">
                    <div class="py-3">
                      <el-input :placeholder="$t('message.conclusions')" type="textarea" :rows="2"
                        v-model="form.brief_conclusion" />
                    </div>
                    <el-card shadow="always">
                      <!-- <vue-document-editor v-model="form.content" /> -->
                      <Tinymce :id="tinymceId" ref="editor" class="naw_Editor" v-model="form.content" :height="800" />
                    </el-card>

                    <el-row :gutter="20" class="mt-3">
                      <el-col :sm="12">
                        <el-input v-if="order_model.conculation" :placeholder="$t('message.conclusions')"
                          v-model="form.used_glasses" />
                      </el-col>
                      <el-col :sm="12">
                        <el-input v-if="order_model.conculation" :placeholder="$t('message.conclusions')"
                          v-model="form.used_cassettes" />
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="
                      el-input el-input--small
                      ser_chek
                      mt-5
                      mr-3
                      pr-3
                      ml-0
                    ">
                    <el-checkbox v-model="form.is_close" :label="$t('message.is_close')" border
                      size="medium"></el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
            <el-tab-pane name="second">
              <el-card class="mt-4" shadow="always" style="width: 1000px; margin: 0 auto">
                <div class="content-table" v-html="form.content"></div>
              </el-card>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
          </el-tabs>
          <!-- confirm  for return back -->
          <el-dialog :append-to-body="true" :title="$t('message.back')" :visible.sync="dialogTableVisible" width="30%">
            <h5>{{ $t("message.reason") }}</h5>
            <el-input type="textarea" :rows="2" v-model="form.reason" />
            <span slot="footer" class="dialog-footer text-center w-100 d-block">
              <el-button @click="dialogTableVisible = false">{{
                $t("message.cancel")
              }}</el-button>
              <el-button type="primary" style="width: 200px" @click="back()">{{
                $t("message.yes")
              }}</el-button>
            </span>
          </el-dialog>
          <!-- end confirmation -->
        </div>
      </div>
    </div>
    <el-drawer title="Изменить тег" :visible.sync="laborantDrawerUpdate" size="60%" :wrapperClosable="false"
      :append-to-body="true" :drawer="laborantDrawerUpdate" @opened="drawerOpened(refUpdate)"
      @closed="drawerClosed(refUpdate)">
      <div v-if="lab_update_open">
        <crm-update :ref="refUpdate" :reloadModel="reopenUpdate" @open="reopenUpdate = true"
          @c-close="afterCloseDrawer"></crm-update>
      </div>
    </el-drawer>
    <el-drawer title="Изменить тег" :visible.sync="watchDrawerConclusion" size="100%" :wrapperClosable="false"
      :append-to-body="true" :drawer="watchDrawerConclusion" @opened="drawerOpened(refWatch)"
      @closed="drawerClosed(refWatch)">
      <div v-if="watch_update_open">
        <crm-show :ref="refWatch" :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="afterCloseDrawer"
          :content="form.content" :form="form" :order_model="order_model" @inside_content="addContent"></crm-show>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./Parts/components/crm-laboratory-update";
import CrmShow from "./Parts/crm-watch-show";
import print from "@/utils/mixins/print";
import users from "@/components/inventory-select/users";
import list from "@/utils/mixins/list";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [print, list],
  props: {
    serviceId: { default: null },
  },
  components: {
    users,
    CrmUpdate,
    CrmShow,
    Tinymce,
  },
  data() {
    return {
      tinymceId: "create_conslusion_tiny",
      fileList: [],
      favorites: [],
      loadingButton: false,
      refUpdate: "laborantDrawerUpdate",
      refWatch: "watchDrawerConclusion",
      active: 0,
      reopenUpdate: false,
      reopenWatchUpdate: false,
      laborantDrawerUpdate: false,
      watchDrawerConclusion: false,
      activeName: "first",
      template: "",
      filterText: "",
      data: [],
      dialogTableVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      templates: [],
      loadingTemplates: false,
      nextButton: true,
      changeTemplateButton: false,
      selectedTemplate: {},
      old_form: "",
      lab_update_open: false,
      watch_update_open: false,
      form: {
        name: "",
        is_close: false,
        diagnos: "",
        header_template: "",
        content: "",
        brief_conclusion: "",
        header_template: "",
        doctor_ids: null,
        hide_header: false,
        hide_footer: false,
        used_cassettes: "",
        used_glasses: "",
        date_time: new Date(),
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      handler: function () { },
    },
    serviceId: {
      handler: function () {
        if (this.serviceId) {
          this.form.service_id = this.serviceId;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      sick_numbers: "sickNumbers/inventory",
      rules: "conclusions/rules",
      model: "conclusions/model",
      order_model: "orders/relation_model",
      columns: "conclusions/columns",
      user_id: "auth/id",
      categoryDocumentations: "categoryDocumentation/list",
    }),
  },

  async mounted() {
    if (this.serviceId) {
      this.form.service_id = this.serviceId;
    }

    if (this.sick_numbers && this.sick_numbers.length === 0) {
      this.loadSickNumber();
    }

    this.categoryDocumentation();
    this.form.date_time = new Date();

    this.form.diagnos = this.order_model.diagnos;
    if (this.order_model.conculation) {
      this.form.used_cassettes = this.order_model.conculation.used_cassettes;
      this.form.used_glasses = this.order_model.conculation.used_glasses;
    }
  },
  methods: {
    ...mapActions({
      loadSickNumber: "sickNumbers/inventory",
      order: "orders/relationShow",
      tempShow: "formTemplate/show",
      doctorTemplate: "formTemplate/doctorTemplate",
      doctorTemplates: "formTemplate/doctorTemplates",
      categoryDocumentationList: "categoryDocumentation/index",
      backTo: "orders/back",
      showModel: "laboratoryConclusions/show",
      getHeader: "conclusions/getHeader",
      save: "conclusions/store",
      update: "conclusions/update",
    }),
    init() {
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message);
          return;
        }
      });
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
      this.reopenUpdate = true;
    },
    drawerClosed(ref) {
      this.reopenUpdate = false;
    },
    categoryDocumentation() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addContent(val) {
      window.tinymce.get(this.tinymceId).setContent(val);
    },
    afterCloseDrawer() {
      this.watchDrawerConclusion = false;
      this.watch_update_open = false;
      this.reloadIfChanged(this.refUpdate);
      const query = {
        id: this.order_model.id,
        relation: true,
      };
      this.lab_update_open = false;
      this.order(query);
    },
    watchConclusion(order_model, form) {
      const query = {
        form: form,
        order_model: order_model,
      };
      this.getHeader(query).then((res) => {
        this.watchDrawerConclusion = true;
        this.watch_update_open = true;
      });
    },
    editLaboratoryConclusion() {
      this.showModel(this.order_model.conculation.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.laborantDrawerUpdate = true;
          this.lab_update_open = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    submit(close = true) {
      this.form.order_id = this.order_model.id;
      this.form.patient_id = this.order_model.patient_id;

      var formData = new FormData();
      for (var key in this.form) {
        if (this.form[key] != null) {
          formData.append(key, this.form[key]);
        } else {
          formData.append(key, "");
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.old_form = res.data.patient_conculation;
              this.loadingButton = false;
              this.$notify({
                title: 'Успешно',
                type: "success",
                offset: 130,
                message: res.message
              });
              this.activeName = "second";
              this.active++;
              if (
                this.$parent &&
                this.$parent.$parent &&
                _.isFunction(this.$parent.$parent.listChanged)
              ) {
                this.$parent.$parent.listChanged();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    updateConculation() {
      this.loadingButton = true;
      this.update({ id: this.old_form.id, data: this.form })
        .then((res) => {
          this.old_form = res.data.patient_conculation;
          this.loadingButton = false;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.activeName = "three";
          this.active++;
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    tabTrigger(event, tab_value) {
      if (this.active >= event) {
        this.active = event - 1;
        this.activeName = tab_value;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        name: "",
        service_id: null,
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      };
      this.old_form = "";
      this.active = 0;
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.nextButton = true;
      this.$emit("c-close", { drawer: "drawerCreateCoculation" });
    },
    NextTab() {
      this.activeName = "second";
      this.active++;
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    stringToHTML(str) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(str, "text/html");
      return doc.body;
    },
    async selected(event) {
      if (event.category_id) {
        await this.tempShow(event.id).then((res) => {
          this.selectedTemplate = res;
          this.form.form_template_id = res.id;
          this.form.content = this.form.content + "<br>" + res.content;
        });
      } else {
        this.selectedTemplate = {};
        this.form.form_template_id = null;
        this.form.content = "";
      }
      if (this.form.content != "") this.nextButton = false;
      else this.nextButton = true;
      this.setToTinyContent();
    },
    addFavorites(id) {
      this.doctorTemplate({ template_id: id })
        .then((res) => {
          this.MyFavorites();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    MyFavorites() {
      this.doctorTemplates()
        .then((res) => {
          this.favorites = res.data;
          this.categoryDocumentation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back(close = true) {
      this.dialogTableVisible = false;
      const query = {
        id: this.order_model.id,
        reason_for_return: this.form.reason,
      };
      this.backTo(query)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.resetForm("form");
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.form.file = event.target.files[0];
      }
    },
    afterOpened() {
      this.form.service_id = this.serviceId;
    },
    afterClosed() {
      this.form.content = "";
      this.form.doctor_ids = null;
      this.setToTinyContent();
    },
    clear() {
      this.form.content = "";
      this.setToTinyContent();
    },
    chanseService(id) {
      this.form.service_id = id;
    },
    setToTinyContent() {
      window.tinymce.get(this.tinymceId).setContent(this.form.content || "");
    },
  },
};
</script>
<style lang="scss">
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 600px !important;
}

.documentation_content .el-card.is-always-shadow {
  min-height: 700px;
}

.editor-content .content-table {
  max-height: 636px;
  overflow: scroll;
}

.editor-content .el-step__title.is-success {
  cursor: pointer;
}

.editor-content .el-step__title.is-process,
.el-step__title.is-wait {
  cursor: no-drop;
}

// .modal-body .el-tabs__header.is-top{
//     // margin:0
// }
.custom-tree-node {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.tree-meta-price {
  display: block;
  padding-right: 50px;
  font-weight: bold;
}

.favorites>.el-tag.el-tag--success.el-tag--light>.el-icon-close:before,
.favorites>.el-icon-close:before {
  content: none !important;
}

.tablePrint {
  .el-tabs__nav-wrap.is-top {
    display: none;
  }

  .boxshii {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // margin-top: 46px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 7px;
    border: 1px solid #ebeef5;
  }
}

.print_title_list {
  justify-content: space-between;
}

.Editor_widht .el-card__body,
.elCARD .el-card__body {
  padding: 0px;
}

.xh_scrol {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.xh_scrol::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.xh_scrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.xh_scrol::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

.zaklucheniya {
  margin: auto;
  text-align: center;
}

.zaklucheniya input {
  width: 206mm;
  /* padding: 20px; */
  margin: 20px 0 -23px;
}
</style>